<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        :treeTitle="$t(`cip.plat.intf.interface.field.title`)"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <el-row>
          <el-col>
            <head-layout
              :head-btn-options="headBtnOptions"
              :head-title="$t(`cip.plat.intf.interface.title.indexHeadTitle`)"
              @head-remove="headRemove"
              @head-add-tabs="headAddTabs"
            ></head-layout>
          </el-col>
        </el-row>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @gird-handle-select-click="selectionChange"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowDetail"
          @row-remove="rowRemove"
          @row-edit="rowEdit"
        >
        </grid-layout>
      </el-main>
      <el-dialog
        v-dialog-drag
        :title="$t(`cip.plat.intf.interface.title.headCateTitle`)"
        :modal="false"
        :modal-append-to-body="false"
        :visible.sync="showTreeDialog"
        v-if="showTreeDialog"
        width="60%"
        class="qmDialog"
      >

        <avue-form :option="treeDialogOption" ref="addForm" v-model="form" @submit="treeNodeSave"></avue-form>
        <span slot="footer">
        <el-button size="small" @click="cancel"
        >{{ $t(`cip.cmn.btn.celBtn`) }}</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
        >{{ $t(`cip.cmn.btn.defBtn`) }}</el-button
        >
      </span>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>


let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index";
import {tree, update as saveOrUpdateTree, remove as removeTree} from '@/api/system/intfServiceType'
import {
  remove,
  getList, rowRemove
} from '@/api/system/interface'
import ServerNameEnum from "@/util/ServerNameEnum";

export default {
  name: "interfaceList",
  components: {
    GridLayout,
    HeadLayout,
    CommonTree
  },
  data() {
    return {
      selectionList: [],
      form: {},
      node: {},
      fullscreen: true,
      showTreeDialog: false,
      treeData: [],
      searchTitle: 'name',
      defaultProps: {
        label: "name",
        value: "id",
        key: "id"
      },
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [{
          label: '上级系统',//this.$t('cip.plat.intf.interface.field.name'),
          prop: 'parentId',
          type: 'tree',
          labelWidth: 130,
          dicUrl: '/api/sinoma-interface/serviceType/tree',
          props: {
            label: 'name',
            value: 'id'
          },
          span: 12,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.intf.interface.field.name`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.intf.interface.field.name`),
              trigger: "blur"
            },
          ],
        }, {
          label: this.$t('cip.plat.intf.interface.field.name'),
          prop: 'name',
          labelWidth: 130,
          span: 12,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.intf.interface.field.name`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.intf.interface.field.name`),
              trigger: "blur"
            },
            {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }

          ],
        },
          {
            label: this.$t('cip.plat.intf.interface.field.code'),
            prop: 'code',
            span: 12,
            dataType: "string",
            placeholder: this.$t(`cip.plat.intf.interface.field.code`),
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.intf.interface.field.code'),
                trigger: "blur"
              },
            ],
          },
          {
            label: this.$t('cip.plat.intf.interface.field.ip'),
            prop: 'ip',
            span: 12,
            maxlength: 100,
            placeholder: this.$t(`cip.plat.intf.interface.field.ip`),
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.ip'),
                trigger: "blur"
              },
              {
                pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
                message: this.$t("cip.cmn.rule.deleteWarning"),
              }
            ],
          },
          {
            label: this.$t('cip.plat.intf.interface.field.port'),
            prop: 'port',
            span: 12,
            maxlength: 100,
            dataType: 'string',
            display: true,
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.port'),
                trigger: "blur"
              },
              {
                pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
                message: this.$t("cip.cmn.rule.deleteWarning"),
              }
            ],
          },
        ]
      }
    },
    ...mapGetters(["permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);

      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          prop: "name",
          type: 'input',
          span: 4,
          placeholder: this.$t("cip.plat.intf.interface.field.name"),
        },
        {
          prop: "code",
          type: 'input',
          span: 4,
          placeholder: this.$t("cip.plat.intf.interface.field.code"),
        },
        {
          prop: "inputName",
          type: 'input',
          span: 4,
          placeholder: this.$t("cip.plat.intf.interface.field.devName"),
        },
        {
          prop: "status",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.intf.interface.field.status`)
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.intf.interface.field.name'),
            prop: 'name',
            overHidden: true,
            width: 200,
            align:'left',
          },
          {
            label: this.$t('cip.plat.intf.interface.field.code'),
            prop: 'code',
            overHidden: true,
            width: 200,
            align:'left',
          },
          {
            label: this.$t('cip.plat.intf.interface.field.devName'),
            prop: 'inputName',
            dicUrl: '/api/sinoma-interface/serviceType/list?type=1',
            props: {
              label: "name",
              value: "code",
            },
            overHidden: true,
            width: 200,
            align:'left',
          },
          {
            label: this.$t('cip.plat.intf.interface.field.devUrl'),
            prop: 'inputUrl',
            overHidden: true,
            align:'left',
          },
          // {
          //   label: this.$t('cip.plat.intf.interface.field.consumerName'),
          //   prop: 'outputName',
          //   overHidden: true,
          // },
          {
            label: this.$t('cip.plat.intf.interface.field.isRetry'),
            prop: 'isRetry',
            align: "center",
            type: 'select',
            overHidden: true,
            dataType: 'string',
            width: 140,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          },
          {
            label: this.$t('cip.plat.intf.interface.field.status'),
            prop: 'status',
            align: "center",
            type: 'select',
            overHidden: true,
            dataType: 'string',
            width: 140,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",

          }
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.interface_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.interface_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
      }
      return result;
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.interface_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.interface_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result;

    },
  },
  mounted() {
    this.onLoad(this.page, {});
    this.initTree()
  },
  methods: {
    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },

    cancel() {
      this.showTreeDialog = false
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    initTree() {
      tree({}).then(res => {
        const {data} = res.data;
        let obj = {name: '全部分类', id: ''}
        this.treeData = data;
        if (this.treeData.length > 0) this.treeData.unshift(obj)
      });
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      const {id} = node;
      this.interfaceId = id;
      this.page.total = 0;
      this.onLoad(this.page);
    },

    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }

      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTree(data.id).then(res => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          done()
        })
      }).catch(() => {
      });
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.form.parentId = this.node.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },
    // 新增左侧树节点
    treeNodeSave(data, done) {
      // this.form.knowledgeCategoryCode=this.form.knowledgeCategoryCode.trim()
      // this.form.knowledgeCategoryName=this.form.knowledgeCategoryName.trim()
      this.$refs.addForm.validate(valid => {
        if (valid) {
          saveOrUpdateTree(this.form).then(res => {
            const {msg, data} = res.data;
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.initTree();
            this.showTreeDialog = false;
          }).catch(err => {
            done()
          })
        }
      })
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    headRemove() {

      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          let ids = this.selectionList.map(item => {
            return item.id
          }).join(',')
          return remove(ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    headAddTabs() {

      let arr = this.treeFindPath(this.treeData, data => data.id === this.interfaceId, "name");

      this.$router.push({
        path: `/interface/interfaceListEdit`,
        query: {
          type: 'add',
          inputName: arr[0],
          interfaceId: this.interfaceId,
          pageType: 'add',
        }
      })
    },
    treeFindPath(tree, func, field = "", path = []) {
      if (!tree) return []
      for (const data of tree) {
        field === "" ? path.push(data) : path.push(data[field]);
        if (func(data)) return path
        if (data.children) {
          const findChildren = this.treeFindPath(data.children, func, field, path)
          if (findChildren.length) return findChildren
        }
        path.pop()
      }
      return []
    },
    rowEdit(row) {
      this.$router.push({
        path: `/interface/interfaceListEdit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/interface/interfaceListEdit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log("aaa", this.selectionList)
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return rowRemove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (this.interfaceId) params.serviceTypeId = this.interfaceId
      getList(
        (page.currentPage - 1) * page.pageSize,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.page.total = data.totalCount
        this.$refs.gridLayOut.page.total = data.totalCount;
        this.tableData = data.list;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
